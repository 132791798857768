import { Roles } from '../helpers/roles';


const Username = ({user, messageUser}) => {

  if(user?.id === messageUser?.id){
    return (
      <span className="vfwmessenger__user vfwmessenger__user--self">
        {messageUser?.nickname}:{' '}
      </span>
    )
  }

  if(messageUser?.chatrooms[0]?.role !== Roles.Mod){
    return (
      <span className="vfwmessenger__user">
        {messageUser?.nickname}:{' '}
      </span>
    )
  }

  if(messageUser?.chatrooms[0]?.role === Roles.Mod){
    return (
      <span className="vfwmessenger__user vfwmessenger__user--mod">
        {messageUser?.nickname}:{' '}
      </span>
    )
  }
}

export default Username;