import React from 'react';
import {gql, useQuery} from '@apollo/client';
import { Link } from 'react-router-dom';

const GET_ROOMS = gql`
  query GetMessages {
    chatrooms {
      id
      name
    }
  }
`;

const Roomselection = () => {
  const {loading, error, data} = useQuery(GET_ROOMS);

  if(loading) return <p>Lädt...</p>;
  if(error) return <p style={{color: 'crimson'}}>Error: {error.message}</p>;

  /*return (
    <div>
      <ul>
        {
          data.chatrooms.map(room => (
            <li key={room.id}><Link to={`/chatroom/${room.id}`}>Chatroom: {room.name}</Link></li>
          ))
        }
      </ul>
    </div>
  )*/
  return (
    <div></div>
  )
}

export default Roomselection;