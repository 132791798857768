import React from 'react';
import ReactDOM from 'react-dom';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  split,
  HttpLink
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import {WebSocketLink} from '@apollo/client/link/ws';
import {setContext} from '@apollo/client/link/context';

import './index.css';
import App from './App';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GQL_URI,
  credentials: 'include',
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_SUBSCRIPTION_URI,
  options: {
    reconnect: true,
    reconnectionAttempts: process.env.RECONNECTION_ATTEMPTS,
    inactivityTimeout: process.env.INACTIVITY_TIMEOUT,
    timeout: process.env.TIMEOUT,
    lazy: true,
  }
});

const authLink = setContext((_, {headers}) => {
  return {
    headers: {
      ...headers,
    }
  }
});

const splitLink = split(
  ({query}) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);


const client = new ApolloClient({
  link: authLink.concat(splitLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          roomMessages: {
            keyArgs: ['type', 'roomId'],

            merge(existing = [], incoming){
              if(existing?.length !== 0 && incoming?.length -existing?.length === 1){
                return [...existing, incoming[incoming?.length -1]];
              }
              return [...incoming, ...existing];
            },
          }
        }
      }
    }
  }),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ApolloProvider>,
  document.getElementById('root')
);

