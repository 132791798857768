import { Triangle, TailSpin, Oval, Audio } from 'react-loader-spinner';


const Spinner = ({type, color}) => {
  const sType = type?.toLowerCase();

  if(!sType || sType === 'triangle'){
    return (
      <div style={{margin: 'auto', width: '75px', paddingTop: '50%'}}>
        <Triangle height="75" width="75" color={color ? color : 'black'} ariaLabel="loading" />
      </div>
    )
  }

  if(sType === 'tailspin'){
    return (
      <div style={{margin: 'auto', width: '75px', paddingTop: '50%'}}>
        <TailSpin height="75" width="75" color={color ? color : 'black'} ariaLabel="loading" />
      </div>
    )
  }

  if(sType === 'audio'){
    return (
      <div style={{margin: 'auto', width: '75px', paddingTop: '50%'}}>
        <Audio height="75" width="75" color={color ? color : 'black'} ariaLabel="loading" />
      </div>
    )
  }

  if(sType === 'oval'){
    return (
      <div style={{margin: 'auto', width: '75px', paddingTop: '50%'}}>
        <Oval height="75" width="75" color={color ? color : 'black'} ariaLabel="loading" />
      </div>
    )
  }
}


export default Spinner