import { useState } from "react";
import React from 'react';

import { Roles } from "../helpers/roles";
import Username from "./Username";

const DeletedMessage = React.forwardRef(({message, user}, ref) => {
  const [showMessage, setShowMessage] = useState(false);

  const handleOnMouseEnterDeleted = (e) => {
    e.stopPropagation();
    setShowMessage(true);
  }

  const handleOnMouseLeaveDeleted = (e) => {
    e.stopPropagation();
    setShowMessage(false);
  }

  var userClass = 'vfwmessenger__msg vfwmessenger__msg--deleted';
  if(user?.id === message?.user.id)
    userClass += ' vfwmessenger__user--self';

  if(message?.user.chatrooms[0]?.role === Roles.Mod)
    userClass += ' vfwmessenger__user--mod';

  if(user?.role === Roles.Mod){
    return (
      <p ref={ref} className={userClass} onMouseEnter={handleOnMouseEnterDeleted} onMouseLeave={handleOnMouseLeaveDeleted}>
        <Username messageUser={message?.user} user={user} />
        {
          showMessage ?
          message?.message : 'Diese Nachricht wurde gelöscht.'
        }
      </p>
    )
  } else {
    return (
      <p ref={ref} className={userClass}>
        <Username messageUser={message?.user} user={user} />
        Diese Nachricht wurde gelöscht.
      </p>
    )
  }
})

export default DeletedMessage;