import {gql, useMutation} from '@apollo/client';
import { useState, useRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from 'react-toastify';

const showLimitToast = (limit) => {
  toast.info(`Du hast das Limit von ${limit} Zeichen überschritten!`, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

const showErrorToast = () => {
  toast.error('Du bist nicht berechtigt Nachrichten zu schreiben!', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });
}

const CREATE_MSG = gql`
  mutation CreateMessage($message: String, $roomId: Int){
    createMessage(message: $message, chatroom: $roomId){
      id
      user {
        id
        nickname
      }
      message
      status {
        status
        description
      }
    }
  }
`;

const Chatinput = ({roomId}) => {
  const inputForm = useRef(null);
  const [message, setMessage] = useState('');
  const [createMessage, {data, loading, error}] = useMutation(CREATE_MSG); //eslint-disable-line no-unused-vars

  // if(loading) return 'data trans';
  // if(error) return `fehler`;

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
    // setTimeout(() => {
    //   const message = e.target.value.trim();
    //   setMessage(message);
    // }, 200);
  }

  const handleKeyDown = (e) => {
    if(e?.keyCode === 13 && !e?.shiftKey){
      e?.preventDefault();
      handleSubmit();
    }
  }

  const handleSubmit = (e) => {
    e?.preventDefault();
    if(!message?.trim()) return;
    if(message?.trim().length > process.env.REACT_APP_MESSAGE_MAX_LENGTH){
      showLimitToast(process.env.REACT_APP_MESSAGE_MAX_LENGTH);
      return;
    }

    createMessage({
      variables: {
        message,
        roomId: roomId
      }
    }).then(({data}) => {
      if(data?.createMessage?.status?.status === 'error'){
        showErrorToast();
      }
      else if(data?.createMessage?.status?.status === 'limit'){
        showLimitToast(data?.createMessage?.status?.description);
      } else {
        setMessage('');
      }
    })
  }

  return(
    <div className="vfwmessenger__msgi__container">
      <form className="vfwmessenger__msgi__form" ref={inputForm} onSubmit={handleSubmit}>
        <TextareaAutosize maxLength={process.env.REACT_APP_MESSAGE_MAX_LENGTH} maxRows="5" className="vfwmessenger__msgi__textarea" value={message} onChange={handleMessageChange} onKeyDown={handleKeyDown} placeholder="Nachricht..." />
        <button className="vfwmessenger__msgi__button" type="submit" title="Senden">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor"><path d="M511.6 36.86l-64 415.1c-1.5 9.734-7.375 18.22-15.97 23.05c-4.844 2.719-10.27 4.097-15.68 4.097c-4.188 0-8.319-.8154-12.29-2.472l-122.6-51.1l-50.86 76.29C226.3 508.5 219.8 512 212.8 512C201.3 512 192 502.7 192 491.2v-96.18c0-7.115 2.372-14.03 6.742-19.64L416 96l-293.7 264.3L19.69 317.5C8.438 312.8 .8125 302.2 .0625 289.1s5.469-23.72 16.06-29.77l448-255.1c10.69-6.109 23.88-5.547 34 1.406S513.5 24.72 511.6 36.86z"/></svg>
        </button>
      </form>
    </div>
  )
}

export default Chatinput;