


const NotFound = () => {

  return (
    <h1>404</h1>
  )
}

export default NotFound;