import {gql, useLazyQuery} from '@apollo/client';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';

import Messages from "./Messages";
import Chatinput from "./Chatinput";
import NotFound from '../pages/NotFound';
import { Roles } from '../helpers/roles';

import { userContext } from '../contexts/user';

const GET_USER = gql`
  query getUser($room: Int){
    user(roomId: $room){
      id
      nickname
      role
    }
  }
`;

const Chatroom = () => {
  const {id} = useParams();
  const intId = Number(id);
  const [getUser, {loading, error, data}] = useLazyQuery(GET_USER, {variables: {room: intId}});
  const [user, setUser] = useState();


  useEffect(() => {
    // getUser();
    const user = async () => {
      await getUser();
    }
    user();
    setUser(data);
  }, [data, getUser])

  // if(isNaN(intId)){
  //   return (
  //     <NotFound />
  //   )
  // }


  if(!loading && (!data?.user?.id || error)){
    /*return (
      <NotFound />
    )*/
  }
  return(
    <userContext.Provider value={data}>
      <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      limit={2}
      />

      <div>
        <Messages roomId={intId} />
        {
          user?.user?.role !== Roles.Viewer &&
          <Chatinput roomId={intId} />
        }
      </div>
    </userContext.Provider>
  )
}

export default Chatroom;